import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CoherenceCustomizations } from '@cseo/styles/lib/CoherenceCustomizations';
import { Customizer } from 'office-ui-fabric-react/lib/Utilities';
import { useLoginOnStartup } from '@employee-experience/common/lib/useLoginOnStartup';
import { Main } from '@employee-experience/shell/lib/Main';
import { ShellStyles } from '@employee-experience/shell/lib/Shell';
import { Footer } from '@employee-experience/shell/lib/Footer';
import { Footer as ManagerHubFooter } from './Shared/Components/Footer';
import { ShellWithStore } from './ShellWithStore';
import { Header } from './Shared/Components/Header';
import { Routes } from './Routes';
import { initializeCustomIcons } from './Shared/initializeCustomIcons';

initializeCustomIcons();

function App(): React.ReactElement {
    useLoginOnStartup();

    return (
        <Customizer {...CoherenceCustomizations}>
            <ShellStyles />
            <BrowserRouter>
                <Header />
                <Main>
                    <Routes />
                </Main>
                <Footer>
                    <ManagerHubFooter />
                </Footer>
            </BrowserRouter>
        </Customizer>
    );
}

render(
    <ShellWithStore>
        <App />
    </ShellWithStore>,
    document.getElementById('app')
);

import { PrimaryButton } from '@employee-experience/common/lib/PrimaryButton';
import { Link } from '@employee-experience/common/lib/Link';

export const ErrorRoot = styled.div`
    text-align: center;
    margin-top: ${window.innerHeight / 2 - 100}px;
    transform: translateY(-50%);
`;

export const ErrorImage = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 38px;
`;

export const ErrorHeading = styled.h1`
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #171717;
    margin-bottom: 16px;
`;

export const ErrorDescription = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: #171717;
    margin-bottom: 30px;
`;

export const LinkToMyHub = styled(Link)`
    font-size: 20px;
`;

export const ContactLink = styled(PrimaryButton)``;

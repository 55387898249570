import { BreakPoints } from '@employee-experience/common/lib/BreakPoints';

export const FooterRoot = styled.div`
    margin-top: 60px;
    background-color: #f8f8f8;
    padding-top: 36px;
    padding-bottom: 36px;
`;

export const FooterText = styled.p`
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #605e5c;
    margin-bottom: 25px;
`;

export const FooterMenus = styled.ul`
    padding-left: 0;
    text-align: center;
`;

export const FooterMenuItem = styled.li`
    display: block;
    text-align: right;
    margin-bottom: 12px;

    @media (min-width: ${BreakPoints.minSmall}) {
        display: inline-block;
        text-align: center;
        margin-left: 37px;
        margin-right: 37px;
        margin-bottom: 0;
    }
`;

import {
    IHRwebSearchResponse,
    IHRwebSearchResults,
    IBestBetSearchResponse,
    IBestBetSearchResults,
} from './Search.types';
import { IComponentResponse } from '@manager-hub/shared/lib/Models/IComponentResponse';

export enum ActionTypes {
    REQUEST_HRWEB_SEARCH_RESULT = 'REQUEST_HRWEB_SEARCH_REUSLT',
    RECEIVE_HRWEB_SEARCH_RESULT = 'RECEIVE_HRWEB_SEARCH_REUSLT',
    FAILED_HRWEB_SEARCH_RESULT = 'FAILED_HRWEB_SEARCH_RESULT',
    REQUEST_BESTBET_SEARCH_RESULT = 'REQUEST_BESTBET_SEARCH_REUSLT',
    RECEIVE_BESTBET_SEARCH_RESULT = 'RECEIVE_BESTBET_SEARCH_REUSLT',
    FAILED_BESTBET_SEARCH_RESULT = 'FAILED_BESTBET_SEARCH_RESULT',
    REQUEST_MANAGER_HUB_SEARCH_RESULT = 'REQUEST_MANAGER_HUB_SEARCH_RESULT',
    RECEIVE_MANAGER_HUB_SEARCH_RESULT = 'RECEIVE_MANAGER_HUB_SEARCH_RESULT',
    FAILED_MANAGER_HUB_SEARCH_RESULT = 'FAILED_MANAGER_HUB_SEARCH_RESULT',
}

export type SearchAction =
    | IRequestHRwebSearchResultAction
    | IReceiveHRwebSearchResultAction
    | IFailedHRwebSearchResultAction
    | IRequestBestBetsSearchResultAction
    | IReceiveBestBetsSearchResultAction
    | IFailedBestBetsSearchResultAction
    | IRequestManagerHubSearchResultAction
    | IReceiveManagerHubSearchResultAction
    | IFailedManagerHubSearchResultAction;

export interface IRequestHRwebSearchResultAction {
    type: ActionTypes.REQUEST_HRWEB_SEARCH_RESULT;
    searchTerm: string;
}

export interface IReceiveHRwebSearchResultAction {
    type: ActionTypes.RECEIVE_HRWEB_SEARCH_RESULT;
    searchTerm: string;
    searchResponse: IHRwebSearchResponse;
}

export interface IFailedHRwebSearchResultAction {
    type: ActionTypes.FAILED_HRWEB_SEARCH_RESULT;
    searchTerm: string;
}

export interface IRequestBestBetsSearchResultAction {
    type: ActionTypes.REQUEST_BESTBET_SEARCH_RESULT;
    searchTerm: string;
}

export interface IReceiveBestBetsSearchResultAction {
    type: ActionTypes.RECEIVE_BESTBET_SEARCH_RESULT;
    searchTerm: string;
    searchResponse: IBestBetSearchResponse;
}
export interface IFailedBestBetsSearchResultAction {
    type: ActionTypes.FAILED_BESTBET_SEARCH_RESULT;
    searchTerm: string;
}

export interface IRequestManagerHubSearchResultAction {
    type: ActionTypes.REQUEST_MANAGER_HUB_SEARCH_RESULT;
    searchTerm: string;
}

export interface IReceiveManagerHubSearchResultAction {
    type: ActionTypes.RECEIVE_MANAGER_HUB_SEARCH_RESULT;
    searchTerm: string;
    searchResults: IComponentResponse[];
}
export interface IFailedManagerHubSearchResultAction {
    type: ActionTypes.FAILED_MANAGER_HUB_SEARCH_RESULT;
    searchTerm: string;
    searchResults: IComponentResponse[];
}

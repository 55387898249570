import * as React from 'react';
import { Switch } from 'react-router-dom';
import { RedirectionPage } from './Shared/Components/Redirection/RedirectionPage';

export function Routes(): React.ReactElement {
    return (
        <Switch>
            <RedirectionPage baseUrl={'Tst'} msEliteUrl={'Test'} />
        </Switch>
    );
}

import { INavAction, ActionTypes } from './Nav.action-types';
import { INavState } from './Nav.types';

export const initialNavState: INavState = {
    isAdmin: false,
    isLoading: false,
    items: [],
};

export function navReducer(prev: INavState = initialNavState, action: INavAction): INavState {
    switch (action.type) {
        case ActionTypes.REQUEST_NAV: {
            return {
                ...prev,
                isLoading: true,
            };
        }
        case ActionTypes.RECEIVE_NAV: {
            return {
                ...prev,
                isLoading: false,
                items: action.items,
                isAdmin: action.items.length > 2,
            };
        }
        default:
            return prev;
    }
}

import {
    ActionTypes,
    IRequestHRwebSearchResultAction,
    IReceiveHRwebSearchResultAction,
    IFailedHRwebSearchResultAction,
    IRequestManagerHubSearchResultAction,
    IReceiveManagerHubSearchResultAction,
    IFailedManagerHubSearchResultAction,
    IRequestBestBetsSearchResultAction,
    IReceiveBestBetsSearchResultAction,
    IFailedBestBetsSearchResultAction,
} from './Search.action-types';
import { IHRwebSearchResponse, IBestBetSearchResponse } from './Search.types';
import { IComponentResponse } from '@manager-hub/shared/lib/Models/IComponentResponse';

export function requestHRwebSearchResult(searchTerm: string): IRequestHRwebSearchResultAction {
    return {
        type: ActionTypes.REQUEST_HRWEB_SEARCH_RESULT,
        searchTerm,
    };
}

export function receiveHRwebSearchResult(
    searchTerm: string,
    searchResponse: IHRwebSearchResponse
): IReceiveHRwebSearchResultAction {
    return {
        type: ActionTypes.RECEIVE_HRWEB_SEARCH_RESULT,
        searchTerm,
        searchResponse,
    };
}

export function failedHRwebSearchResult(searchTerm: string): IFailedHRwebSearchResultAction {
    return {
        type: ActionTypes.FAILED_HRWEB_SEARCH_RESULT,
        searchTerm,
    };
}

export function requestBestBetSearchResult(searchTerm: string): IRequestBestBetsSearchResultAction {
    return {
        type: ActionTypes.REQUEST_BESTBET_SEARCH_RESULT,
        searchTerm,
    };
}

export function receiveBestBetSearchResult(
    searchTerm: string,
    searchResponse: IBestBetSearchResponse
): IReceiveBestBetsSearchResultAction {
    return {
        type: ActionTypes.RECEIVE_BESTBET_SEARCH_RESULT,
        searchTerm,
        searchResponse,
    };
}

export function failedBestBetSearchResult(searchTerm: string): IFailedBestBetsSearchResultAction {
    return {
        type: ActionTypes.FAILED_BESTBET_SEARCH_RESULT,
        searchTerm,
    };
}

export function requestManagerHubSearchResult(
    searchTerm: string
): IRequestManagerHubSearchResultAction {
    return {
        type: ActionTypes.REQUEST_MANAGER_HUB_SEARCH_RESULT,
        searchTerm,
    };
}

export function receiveManagerHubSearchResult(
    searchTerm: string,
    searchResults: IComponentResponse[]
): IReceiveManagerHubSearchResultAction {
    return {
        type: ActionTypes.RECEIVE_MANAGER_HUB_SEARCH_RESULT,
        searchTerm,
        searchResults,
    };
}

export function failedManagerHubSearchResult(
    searchTerm: string
): IFailedManagerHubSearchResultAction {
    return {
        type: ActionTypes.FAILED_MANAGER_HUB_SEARCH_RESULT,
        searchTerm,
    };
}

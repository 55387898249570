import { TelemetryClient } from '@employee-experience/core/lib/TelemetryClient';
import { HttpClient } from '@employee-experience/core/lib/HttpClient';
import { ADALClient } from '@employee-experience/core/lib/ADALClient';
import { ComponentLoader } from '@employee-experience/core/lib/ComponentLoader';
import { GraphClient } from '@employee-experience/core/lib/GraphClient';
import { Shell } from '@employee-experience/shell/lib/Shell';
import { withStore } from '@employee-experience/shell/lib/withStore';
import { StoreBuilder } from '@employee-experience/shell/lib/StoreBuilder';
import { ReducerRegistry } from '@employee-experience/shell/lib/ReducerRegistry';
import { NavReducerName } from './Shared/Components/Nav/Nav.resources';
import { navReducer, initialNavState } from './Shared/Components/Nav/Nav.reducer';
import { navSaga } from './Shared/Components/Nav/Nav.sagas';
import { searchSagas } from './Pages/Search/Search.sagas';
import { IManagerHubAppState } from './App.types';

const telemetryClient = new TelemetryClient({
    instrumentationKey: __INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: __IS_PRODUCTION__ ? 'Production' : 'Non-Production',
        ServiceOffering: 'Human Resources',
        ServiceLine: 'HR Experiences',
        Service: 'Employee Experience',
        ComponentName: 'Manager Dashboard',
        ComponentId: '24ef5eca-6fa5-4620-91dc-34fbb1215e66',
    },
    defaultProperties: {
        appName: __APP_NAME__,
    },
});
const authClient = new ADALClient(
    {
        clientId: __CLIENT_ID__,
        redirectUri: `${window.location.origin}/adal.html`,
    },
    telemetryClient
);
const httpClient = new HttpClient(telemetryClient, authClient);
const graphClient = new GraphClient(httpClient);
const componentLoader = new ComponentLoader(telemetryClient);

const reducerRegistry = new ReducerRegistry().register(NavReducerName, navReducer);
const storeResult = new StoreBuilder<IManagerHubAppState>(reducerRegistry, {
    [NavReducerName]: initialNavState,
})
    .configureSaga({ telemetryClient, authClient, httpClient, componentLoader, graphClient })
    // .configureLogger(process.env.NODE_ENV === 'development')
    .addRootSagas([navSaga, searchSagas])
    .configurePersistor({
        whitelist: process.env.NODE_ENV === 'development' ? [] : undefined,
    })
    .build();

export const ShellWithStore = withStore(storeResult)(Shell);

import * as React from 'react';
import { Header as EEHeader } from '@employee-experience/shell/lib/Header';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';

export function Header(): React.ReactElement {
    const { telemetryClient } = React.useContext(ComponentContext);

    return (
        <EEHeader
            headerLabel={__APP_NAME__}
            appNameSettings={{
                label: __APP_NAME__,
            }}
            telemetryHook={telemetryClient}
        />
    );
}

import { CustomProperties } from '@employee-experience/common/lib/Models';

export function getSharedHttpConfig(url: string): CustomProperties {
    const resource = __MANAGER_API_RESOURCE_URI__;
    const subscriptionKey = __MY_HUB_APIM_KEY__;

    const httpConfig: CustomProperties = {
        url,
        resource,
    };

    if (subscriptionKey) {
        httpConfig.headers = {
            'Dwp-Apim-Subscription-Key': subscriptionKey,
        };
    }

    return httpConfig;
}

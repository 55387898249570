import { put, getContext, all, takeLatest, call } from 'redux-saga/effects';
import {
    receiveHRwebSearchResult,
    failedHRwebSearchResult,
    receiveManagerHubSearchResult,
    failedManagerHubSearchResult,
    receiveBestBetSearchResult,
    failedBestBetSearchResult,
} from './Search.actions';
import {
    IRequestHRwebSearchResultAction,
    IRequestManagerHubSearchResultAction,
    ActionTypes,
    IRequestBestBetsSearchResultAction,
} from './Search.action-types';

import { IHRwebSearchResponse, IBestBetSearchResponse } from './Search.types';
import { IComponentResponse } from '@manager-hub/shared/lib/Models/IComponentResponse';
import { IHttpClient, IHttpClientResult } from '@employee-experience/common/lib/Models/IHttpClient';
import { SagaIterator } from 'redux-saga';
import { getSharedHttpConfig } from '../../Shared/getSharedHttpConfig';

function* fetchHRwebSearchResults(action: IRequestHRwebSearchResultAction): SagaIterator {
    const { searchTerm } = action;
    const maxResults = __HRWEB_MAX_SEARCH_RESULTS__;

    const httpClient: IHttpClient = yield getContext('httpClient');
    const searchUrl = __HRWEB_CONTENT_API_BASE_URL__ + '/api/v1/HRWebContent/SearchContent';
    let response: IHttpClientResult<IHRwebSearchResponse> | null = null;
    try {
        response = yield call([httpClient, httpClient.request], {
            ...getSharedHttpConfig(searchUrl),
            method: 'post',
            data: {
                queryText: searchTerm,
                RowLimit: maxResults,
            },
        });

        yield put(receiveHRwebSearchResult(searchTerm, response.data));
    } catch (ex) {
        yield put(failedHRwebSearchResult(searchTerm));
    }
}

function* fetchBestBetSearchResults(action: IRequestBestBetsSearchResultAction): SagaIterator {
    const { searchTerm } = action;

    const httpClient: IHttpClient = yield getContext('httpClient');
    const searchUrl = __HRWEB_CONTENT_API_BASE_URL__ + '/api/v1/HRWebContent/SearchBestBets';
    let searchResults: IHttpClientResult<IBestBetSearchResponse> | null = null;
    try {
        searchResults = yield call([httpClient, httpClient.request], {
            ...getSharedHttpConfig(searchUrl),
            method: 'post',
            data: {
                queryText: searchTerm,
            },
        });

        yield put(receiveBestBetSearchResult(searchTerm, searchResults.data));
    } catch (ex) {
        yield put(failedBestBetSearchResult(searchTerm));
    }
}

function* fetchManagerHubSearchResults(action: IRequestManagerHubSearchResultAction): SagaIterator {
    const { searchTerm } = action;

    const httpClient: IHttpClient = yield getContext('httpClient');
    const searchUrl = __CONFIG_API_BASE_URL__ + '/api/Components/search';
    let searchResults: IHttpClientResult<IComponentResponse[]> | null = null;
    try {
        searchResults = yield call([httpClient, httpClient.request], {
            ...getSharedHttpConfig(searchUrl),
            method: 'post',
            data: {
                SearchTerm: searchTerm,
            },
        });

        yield put(
            receiveManagerHubSearchResult(
                searchTerm,
                searchResults.data.map((r) => ({
                    ...r,
                    bundleLocation: `${__BASE_URL__}/${r.bundleLocation}`,
                }))
            )
        );
    } catch (ex) {
        yield put(failedManagerHubSearchResult(searchTerm));
    }
}

export function* searchSagas(): SagaIterator {
    yield all([takeLatest(ActionTypes.REQUEST_HRWEB_SEARCH_RESULT, fetchHRwebSearchResults)]);
    yield all([takeLatest(ActionTypes.REQUEST_BESTBET_SEARCH_RESULT, fetchBestBetSearchResults)]);
    yield all([
        takeLatest(ActionTypes.REQUEST_MANAGER_HUB_SEARCH_RESULT, fetchManagerHubSearchResults),
    ]);
}

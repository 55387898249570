import { IReceiveNavAction, IRequestNavAction, ActionTypes } from './Nav.action-types';
import { INavItem } from './Nav.types';

export function requestNav(): IRequestNavAction {
    return {
        type: ActionTypes.REQUEST_NAV,
    };
}

export function receiveNav(items: INavItem[]): IReceiveNavAction {
    return {
        type: ActionTypes.RECEIVE_NAV,
        items,
    };
}

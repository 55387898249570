import * as React from 'react';
import * as Styled from './RedirectionPage.styled';
import { IRedirectionPageProps } from './RedirectionPage.types';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { getClickFeature, getFeature } from '@employee-experience/common/lib/UsageTelemetryHelper';
import { UsageEventName, EventType } from '@employee-experience/common/lib/Models';

export function RedirectionPage(props: IRedirectionPageProps): React.ReactElement {
    const { telemetryClient } = React.useContext(ComponentContext);
    const feature = getFeature('ManagerHub.RedirectionPage', 'Redirection');
    telemetryClient.trackEvent({
        eventName: UsageEventName.PageLoad,
        type: EventType.User,
        businessTransactionId: '',
        ...feature,
    });

    return (
        <Styled.ErrorRoot>
            <Styled.ErrorHeading>ManagerHub is now MyHub web app!</Styled.ErrorHeading>
            <Styled.ErrorDescription>
                We&apos;ve got a new look and feel along with efficient ways to complete manager
                tasks, get data and insights, and more.
            </Styled.ErrorDescription>
            <Styled.LinkToMyHub
                to={'https://myhub.microsoft.com/dashboard/team'}
                // to={'https://myhub-web-nonprod.azurewebsites.net/dashboard/team'}
                title={`Link to MyHub`}
                target="_blank"
                {...getClickFeature(feature, 'RedirectToMyHubLink')}
            >
                aka.ms/myhub &nbsp;
                {/* aka.ms/managerhubuat &nbsp; */}
                <Icon iconName="OpenInNewWindow" />
            </Styled.LinkToMyHub>
        </Styled.ErrorRoot>
    );
}

import { BreakPoints } from '@employee-experience/common/lib/BreakPoints';

export const Container = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    padding-right: 5vw;

    /* @media (max-width: 1656px) {
        width: 1200px;
    }

    @media (max-width: 1248px) {
        width: 792px;
    }

    @media (max-width: 840px) {
        width: 384px;
    }

    @media (max-width: ${BreakPoints.minSmall}) {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        margin-left: 0;
        margin-right: 0;
    } */
`;

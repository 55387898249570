import { INavItemsResponse, INavItem } from './Nav.types';

export function mapNav(response: INavItemsResponse): INavItem[] {
    const navItems: INavItem[] = [];

    Object.keys(response).forEach((href: string) => {
        const responseItem = response[href];
        navItems.push({
            key: href,
            name: responseItem.navText,
            icon: responseItem.navIcon || responseItem.navImage,
            href,
            bundleLocation: responseItem.bundleLocation,
            componentName: responseItem.componentName,
        });
    });

    return navItems;
}

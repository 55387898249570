import { INavItem } from './Nav.types';

export enum ActionTypes {
    REQUEST_NAV = 'REQUEST_NAV',
    RECEIVE_NAV = 'RECEIVE_NAV',
}

export type INavAction = IRequestNavAction | IReceiveNavAction;

export interface IRequestNavAction {
    type: ActionTypes.REQUEST_NAV;
}

export interface IReceiveNavAction {
    type: ActionTypes.RECEIVE_NAV;
    items: INavItem[];
}
